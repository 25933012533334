/* eslint-disable no-case-declarations */
import elements from 'config/elements';
import * as routes from 'config/routes';
import ContainerProps from 'containers/definitions/ContainerProps';
import toPath from 'core/lib/toPath';

import { L10nMessages, LocalizedDate, LocalizedMessage } from 'core/l10n/components';
import * as OvationsApi from 'core/ovations-api';
import { ClaimStatus, ReconciliationStatus, RewardPackageType } from 'core/ovations-api/enums';
import { monetize } from 'core/util/strings';
import CustomerRewardType from 'enums/CustomerRewardType';
import FormConfigType from 'enums/FormConfigType';
import withData from 'lib/withData';
import { claimLoader } from 'loaders/claimLoaders';
import { promotionLoader } from 'loaders/promotionLoaders';
import { find, includes } from 'lodash';
import * as OvationsPortalApi from 'ovations-portal-api';
import { CustomerClaim } from 'ovations-portal-api/definitions/CustomerRewardSummary';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import * as claim from 'redux-modules/claim';
import ValidatedClaimAmountHeader from 'components/claims/ValidatedClaimAmountHeader';
import Reward from 'core/ovations-api/definitions/rewards/Reward';

export type ClaimDetailContainerProps = ContainerProps<{
    claimNumber?: string;
    promotionNumber?: string;
    formConfigType?: FormConfigType;
}>;

interface ClaimDetailContainerState {
    claim: OvationsPortalApi.Types.ClaimDetail;
    isSavingChanges: boolean;
    wasValidated: boolean;
    touchedReward: boolean;
    eSignDisclosureHasBeenReviewed: boolean;
    alternatePayeeSelected: boolean;
}

export class ClaimDetailContainer extends React.Component<ClaimDetailContainerProps, ClaimDetailContainerState> {
    constructor(props: ClaimDetailContainerProps) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState(): ClaimDetailContainerState {
        const claimDetail = ClaimDetailContainer.getClaimDetail(this.props) || claim.emptyClaimDetail;
        return {
            claim: claimDetail,
            isSavingChanges: false,
            wasValidated: false,
            touchedReward: false,
            eSignDisclosureHasBeenReviewed: claimDetail.eSignAgreementAcceptance,
            alternatePayeeSelected: false,
        };
    }

    componentDidUpdate(prevProps: ClaimDetailContainerProps) {
        const { claimNumber } = this.props.match.params;
        const nextClaimDetail = ClaimDetailContainer.getClaimDetail(this.props);

        if (claimNumber && nextClaimDetail && ClaimDetailContainer.getClaimDetail(prevProps) !== nextClaimDetail) {
            this.setState({
                claim: nextClaimDetail,
            });
        }
    }

    static getActivePromotion(props: ClaimDetailContainerProps): OvationsPortalApi.Types.Promotion | undefined {
        const { promotionNumber } = props.match.params;
        if (promotionNumber) {
            return find(props.promotion.list, { number: +promotionNumber });
        }
        const claimBeingEdited = ClaimDetailContainer.getClaimDetail(props);
        if (claimBeingEdited) {
            return claimBeingEdited.promotion;
        }
    }

    static getClaimDetail(props: ClaimDetailContainerProps): OvationsPortalApi.Types.ClaimDetail | undefined {
        const { claimNumber } = props.match.params;
        if (claimNumber) {
            const claimsFromRewards = props.claim.results.filter(
                (claimValues) => claimValues.type === CustomerRewardType.Claim,
            ) as CustomerClaim[];

            return claimsFromRewards
                .map((claimRewardSummary) => claimRewardSummary.claim)
                .find((claimDetail) => claimDetail.number === Number(claimNumber));
        }
        return;
    }

    getFriendlyClaimStatus(claimStatus: OvationsApi.Enums.ClaimStatus): keyof L10nMessages {
        switch (claimStatus) {
            case ClaimStatus.Submitted:
            case ClaimStatus.PendingValidation:
            case ClaimStatus.Validated:
            case ClaimStatus.PendingExport:
            case ClaimStatus.PotentialDuplicate:
            case ClaimStatus.Executing:
            case ClaimStatus.NeedsReview:
                return 'claimsPage_statusProcessing';
            case ClaimStatus.Exported:
            case ClaimStatus.Fulfilled:
                return 'claimsPage_statusProcessed';
            case ClaimStatus.Incomplete:
                return 'claimsPage_statusIncomplete';
            case ClaimStatus.Disqualified:
                return 'claimsPage_statusDisqualified';
            case ClaimStatus.Mailed:
                return 'claimsPage_statusMailed';
            case ClaimStatus.Issued:
                return 'claimsPage_statusIssued';
            case ClaimStatus.PendingRedemption:
                return 'claimsPage_statusReadyToRedeem';
            case ClaimStatus.ConfirmingReferral:
                return 'claimsPage_statusConfirmingReferral';
        }
    }

    getFriendlyReconciliationStatus(
        reconciliationStatus: OvationsApi.Enums.ReconciliationStatus,
    ): keyof L10nMessages | null {
        switch (reconciliationStatus) {
            case OvationsApi.Enums.ReconciliationStatus.Disqualified:
            case OvationsApi.Enums.ReconciliationStatus.Incomplete:
            case OvationsApi.Enums.ReconciliationStatus.Validated:
                return null;
            case OvationsApi.Enums.ReconciliationStatus.PendingSubmission:
                return 'claimsPage_label_requiresProofOfPurchase';
            case OvationsApi.Enums.ReconciliationStatus.PendingValidation:
                return 'claimsPage_label_validatingProofOfPurchase';
            default:
                return null;
        }
    }

    getRewardStatus(reward: Reward) {
        let rewardStatus = reward.status;
        if (reward.cardRewardDetail && reward.cardRewardDetail.status) {
            rewardStatus = reward.cardRewardDetail.status;
        }
        return rewardStatus;
    }

    getFriendlyRewardStatus(rewardStatus: OvationsApi.Enums.RewardStatus): keyof L10nMessages {
        switch (rewardStatus) {
            case OvationsApi.Enums.RewardStatus.Activated:
                return 'claimsPage_rewardStatusActivated';
            case OvationsApi.Enums.RewardStatus.Deactivated:
                return 'claimsPage_rewardStatusDeactivated';
            case OvationsApi.Enums.RewardStatus.Expired:
                return 'claimsPage_rewardStatusExpired';
            case OvationsApi.Enums.RewardStatus.Exported:
                return 'claimsPage_rewardStatusProcessed';
            case OvationsApi.Enums.RewardStatus.Inactive:
                return 'claimsPage_rewardStatusInactive';
            case OvationsApi.Enums.RewardStatus.Issued:
                return 'claimsPage_rewardStatusIssued';
            case OvationsApi.Enums.RewardStatus.Mailed:
                return 'claimsPage_rewardStatusMailed';
            case OvationsApi.Enums.RewardStatus.PendingExport:
                return 'claimsPage_rewardStatusPendingExport';
            case OvationsApi.Enums.RewardStatus.Processed:
                return 'claimsPage_rewardStatusProcessed';
            case OvationsApi.Enums.RewardStatus.Replaced:
                return 'claimsPage_rewardStatusReplaced';
            case OvationsApi.Enums.RewardStatus.Undeliverable:
                return 'claimsPage_rewardStatusUndeliverable';
            case OvationsApi.Enums.RewardStatus.Validated:
                return 'claimsPage_rewardStatusValidated';
        }
    }

    getFriendlyPOPButtonText(claimDetail: OvationsPortalApi.Types.ClaimDetail): keyof L10nMessages {
        if (this.isCreatingReconciliation(claimDetail)) {
            return 'claimsPage_action_provideProofOfPurchase';
        }
        return 'claimsPage_action_editProofOfPurchase';
    }

    getPrefundQuestions() {
        const { promotion } = this.state.claim;
        return promotion.questions.filter((question) => question.isForPreFund);
    }

    getNonPrefundQuestions() {
        const { promotion } = this.state.claim;
        return promotion.questions.filter((question) => !question.isForPreFund);
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    getRewardType(rewardType: OvationsApi.Enums.RewardPackageType): keyof L10nMessages {
        switch (rewardType) {
            case OvationsApi.Enums.RewardPackageType.RetailCard:
            case OvationsApi.Enums.RewardPackageType.SingleLoadUSA:
            case OvationsApi.Enums.RewardPackageType.SingleLoadCAN:
            case OvationsApi.Enums.RewardPackageType.ReloadableUSA:
            case OvationsApi.Enums.RewardPackageType.ReloadableCAN:
            case OvationsApi.Enums.RewardPackageType.SingleLoadUSAGalileo:
            case OvationsApi.Enums.RewardPackageType.ReloadableUSAGalileo:
            case OvationsApi.Enums.RewardPackageType.ReloadableCANVisa:
                return 'claimsPage_rewardType__card';
            case OvationsApi.Enums.RewardPackageType.VirtualUSA:
                return 'claimsPage_rewardType__virtualCard';
            case OvationsApi.Enums.RewardPackageType.CheckUSA:
                return 'claimsPage_rewardType__check';
            case OvationsApi.Enums.RewardPackageType.Points:
                return 'claimsPage_rewardType__points';
            default:
                return 'claimsPage_rewardType__card';
        }
    }

    generateClaimFormQuestionResponses(questions: OvationsPortalApi.Types.PromotionQuestion[]) {
        if (questions.length === 0) {
            return null;
        }

        const { answers } = this.state.claim;

        const answerList = questions
            .filter((question) => !!answers[question.id])
            .map((question) => {
                const answer = answers[question.id];
                switch (answer.type) {
                    case OvationsApi.Enums.QuestionType.Text:
                        const promotionTextQuestionAnswer =
                            answer as {} as OvationsApi.Types.PromotionTextQuestionAnswer;
                        return (
                            <React.Fragment key={question.id}>
                                <dt>{question.question.title}</dt>
                                <dd>{promotionTextQuestionAnswer.value}</dd>
                            </React.Fragment>
                        );

                    case OvationsApi.Enums.QuestionType.Number:
                        const promotionNumberQuestionAnswer =
                            answer as {} as OvationsApi.Types.PromotionNumberQuestionAnswer;
                        return (
                            <React.Fragment key={question.id}>
                                <dt>{question.question.title}</dt>
                                <dd>{promotionNumberQuestionAnswer.value}</dd>
                            </React.Fragment>
                        );

                    case OvationsApi.Enums.QuestionType.DateTime:
                        const promotionDateTimeQuestionAnswer =
                            answer as {} as OvationsApi.Types.PromotionDateTimeQuestionAnswer;
                        return (
                            <React.Fragment key={question.id}>
                                <dt>{question.question.title}</dt>
                                <dd>
                                    {promotionDateTimeQuestionAnswer.value ? (
                                        <LocalizedDate date={promotionDateTimeQuestionAnswer.value} />
                                    ) : (
                                        'N/A'
                                    )}
                                </dd>
                            </React.Fragment>
                        );

                    case OvationsApi.Enums.QuestionType.FileUpload:
                        const promotionFileUploadQuestionAnswer =
                            answer as {} as OvationsApi.Types.PromotionFileUploadQuestionAnswer;

                        return (
                            <React.Fragment key={question.id}>
                                <dt>{question.question.title}</dt>
                                <dd>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={promotionFileUploadQuestionAnswer.fileUrl || ''}
                                    >
                                        {promotionFileUploadQuestionAnswer.fileName}
                                    </a>
                                </dd>
                            </React.Fragment>
                        );

                    case OvationsApi.Enums.QuestionType.Dropdown:
                        const promotionDropdownQuestionAnswer =
                            answer as {} as OvationsApi.Types.PromotionDropDownQuestionAnswer;
                        return (
                            <React.Fragment key={question.id}>
                                <dt>{question.question.title}</dt>
                                <dd>{promotionDropdownQuestionAnswer.value}</dd>
                            </React.Fragment>
                        );

                    case OvationsApi.Enums.QuestionType.CascadingDropdown:
                        return (
                            <React.Fragment key={question.id}>
                                <dt>{question.question.title}</dt>
                                <dd>
                                    {claim.selectors.resolveCascadingDropdownAnswer(
                                        question as OvationsApi.Types.PromotionCascadingDropdownQuestion,
                                        answer as OvationsApi.Types.PromotionCascadingDropdownQuestionAnswer,
                                    )}
                                </dd>
                            </React.Fragment>
                        );
                    case OvationsApi.Enums.QuestionType.MultiSelect:
                        const promotionMultiSelectQuestionAnswer =
                            answer as {} as OvationsApi.Types.PromotionMultiSelectQuestionAnswer;

                        return (
                            <React.Fragment key={question.id}>
                                <dt>{question.question.title}</dt>
                                <dd>{promotionMultiSelectQuestionAnswer.selections.join(', ')}</dd>
                            </React.Fragment>
                        );

                    default:
                        return null;
                }
            });

        if (answerList.length === 0) {
            return null;
        }

        return <dl>{answerList}</dl>;
    }

    hasProofOfPurchaseAnswer(claimDetail: OvationsPortalApi.Types.ClaimDetail) {
        // this method can be removed/refactored after code
        // quality task on BE to return isForPrefund bool in answer object
        const questions = claimDetail.promotion.questions.filter((q) => q.isForPreFund === false);

        let found = false;
        questions.forEach((q) => {
            if (!found && q.id in claimDetail.answers) {
                found = true;
            }
        });
        return found;
    }

    isCreatingReconciliation(claimDetail: OvationsPortalApi.Types.ClaimDetail) {
        return (
            claimDetail.isForPrefund &&
            claimDetail.reconciliationStatus === ReconciliationStatus.PendingSubmission &&
            (ClaimStatus[claimDetail.status] === ClaimStatus.Validated ||
                ClaimStatus[claimDetail.status] === ClaimStatus.PendingExport ||
                ClaimStatus[claimDetail.status] === ClaimStatus.Exported ||
                ClaimStatus[claimDetail.status] === ClaimStatus.Mailed ||
                ClaimStatus[claimDetail.status] === ClaimStatus.Issued ||
                ClaimStatus[claimDetail.status] === ClaimStatus.Fulfilled)
        );
    }

    isEditingReconciliation(claimDetail: OvationsPortalApi.Types.ClaimDetail) {
        return (
            claimDetail.isForPrefund &&
            includes(
                [ReconciliationStatus.PendingValidation, ReconciliationStatus.Incomplete],
                claimDetail.reconciliationStatus,
            )
        );
    }

    isReconciliationClaimEditable(reconciliationStatus: ReconciliationStatus) {
        const truthyStatuses = [ReconciliationStatus.Incomplete, ReconciliationStatus.PendingValidation];
        return includes(truthyStatuses, reconciliationStatus);
    }

    generateReconciliationLink(claimDetail: OvationsPortalApi.Types.ClaimDetail) {
        if (this.isCreatingReconciliation(claimDetail)) {
            return toPath(routes.CREATE_RECONCILIATION, {
                claimNumber: claimDetail.number,
            });
        }
        if (
            this.isEditingReconciliation(claimDetail) &&
            this.isReconciliationClaimEditable(claimDetail.reconciliationStatus)
        ) {
            return toPath(routes.EDIT_RECONCILIATION, {
                claimNumber: claimDetail.number,
            });
        }
        return null;
    }

    generateBreadcrumbs(claimDetail: OvationsPortalApi.Types.ClaimDetail) {
        return (
            <div className={elements.claimDetail.class.detailBreadcrumbContainer}>
                <Link to={routes.CLAIMS} className={elements.claimDetail.class.detailBreadcrumbParent}>
                    All Rewards
                </Link>
                &nbsp;/&nbsp;
                <span className={elements.claimDetail.class.detailBreadcrumbChild}>Claim {claimDetail.number}</span>
            </div>
        );
    }

    renderProofOfPurchase(claimDetail: OvationsPortalApi.Types.ClaimDetail, claimFormDetails: JSX.Element | null) {
        if (this.hasProofOfPurchaseAnswer(claimDetail) && claimDetail.isForPrefund) {
            return (
                <div>
                    <hr />
                    <div>
                        <h5 className="h5">Proof Of Purchase</h5>
                        {claimFormDetails}
                    </div>
                </div>
            );
        }
    }

    renderPrefundForm() {
        const prefundQuestions = this.getPrefundQuestions();
        const prefundFormDetails = this.generateClaimFormQuestionResponses(prefundQuestions);

        if (prefundFormDetails) {
            return (
                <div>
                    <hr />
                    <div>
                        <h5 className="h5">Claim Form</h5>
                        {prefundFormDetails}
                    </div>
                </div>
            );
        }

        return null;
    }

    renderClaimForm(claimDetail: OvationsPortalApi.Types.ClaimDetail, claimFormDetails: JSX.Element | null) {
        if (!claimDetail.isForPrefund) {
            return (
                <div>
                    <hr />
                    <div>
                        <h5 className="h5">Claim Form</h5>
                        {claimFormDetails}
                    </div>
                </div>
            );
        }
    }

    renderClaimDetails(claimDetail: OvationsPortalApi.Types.ClaimDetail) {
        const reconcilationLink = this.generateReconciliationLink(claimDetail);
        const { payeeDetails } = claimDetail;
        const friendlyReconciliationStatus = this.getFriendlyReconciliationStatus(claimDetail.reconciliationStatus);
        return (
            <>
                <h2>
                    <ValidatedClaimAmountHeader claimDetail={claimDetail} />
                </h2>
                <hr />
                <Row>
                    <Col>
                        <dl>
                            <dt key="promotion-label">
                                <LocalizedMessage id="claimDetail_label_promotion" />:
                            </dt>
                            <dd key="promotion-value">{claimDetail.promotion.name}</dd>
                            <dt key="submission-label">
                                <LocalizedMessage id="claimDetail_label_submission_date" />:
                            </dt>
                            <dd key="submission-value">
                                <LocalizedDate date={claimDetail.submissionDate} />
                            </dd>
                            <dt key="status-label">
                                <LocalizedMessage id="claimDetail_label_status" />:
                            </dt>
                            <dd key="status-value">
                                <LocalizedMessage id={this.getFriendlyClaimStatus(claimDetail.status)} />
                                {friendlyReconciliationStatus && (
                                    <>
                                        {' - '} <LocalizedMessage id={friendlyReconciliationStatus} />
                                    </>
                                )}
                            </dd>
                            {payeeDetails && (
                                <div>
                                    <dt>
                                        <LocalizedMessage id="claimDetail_label_send_to" />:
                                    </dt>
                                    <dd className="mb-0">
                                        {payeeDetails.firstName} {payeeDetails.lastName}
                                    </dd>
                                    <dd className="mb-0">{payeeDetails.address1}</dd>
                                    <dd className="mb-0">
                                        {payeeDetails.city}, {payeeDetails.state} {payeeDetails.zip}
                                    </dd>
                                </div>
                            )}
                            {reconcilationLink && (
                                <Link to={reconcilationLink}>
                                    <Button className={elements.FAQ.class.faqResponse} color="primary">
                                        <LocalizedMessage id={this.getFriendlyPOPButtonText(claimDetail)} />
                                    </Button>
                                </Link>
                            )}
                        </dl>
                        {claimDetail.isPartialReward && (
                            <p className="font-italic">
                                <LocalizedMessage id="claimDetail_label_partial_reward" />
                            </p>
                        )}
                    </Col>
                    <Col id="details">
                        <strong>
                            <LocalizedMessage id="claimDetail_label_details" />:
                        </strong>
                        {claimDetail.rewards?.map((x, i) => {
                            const rewardStatus = this.getRewardStatus(x);
                            const rewardPackagePoints = x.rewardCalculation.rewardPackages.find((rp) => {
                                return rp.rewardPackageType === RewardPackageType.Points;
                            });
                            if (rewardPackagePoints) {
                                return (
                                    <div id={`detail${i}`} key={i}>
                                        {`${x.validatedAmount}`} {`${rewardPackagePoints.rewardPackageType}`}
                                        {rewardStatus ? (
                                            <span>
                                                {' - '}
                                                <LocalizedMessage id={this.getFriendlyRewardStatus(rewardStatus)} />
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                );
                            }
                            return (
                                <div id={`detail${i}`} key={i}>
                                    {`${monetize(x.validatedAmount)} ${
                                        x.rewardCalculation.rewardPackages[0].rewardPackageType
                                    }`}
                                    {rewardStatus ? (
                                        <span>
                                            {' - '}
                                            <LocalizedMessage id={this.getFriendlyRewardStatus(rewardStatus)} />
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            );
                        })}
                    </Col>
                </Row>
            </>
        );
    }

    render() {
        const claimDetail = this.state.claim;
        const nonPrefundQuestions = this.getNonPrefundQuestions();
        const claimFormDetails = this.generateClaimFormQuestionResponses(nonPrefundQuestions);
        return (
            <div className="container py-4">
                {this.generateBreadcrumbs(claimDetail)}
                <Card className="mb-3">
                    <CardBody>
                        {this.renderClaimDetails(claimDetail)}
                        {this.renderClaimForm(claimDetail, claimFormDetails)}
                        {this.renderPrefundForm()}
                        {this.renderProofOfPurchase(claimDetail, claimFormDetails)}
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const ClaimDetailContainerWithData = withData<ClaimDetailContainerProps>(
    claimLoader,
    promotionLoader,
)(ClaimDetailContainer);
export default connect(/* istanbul ignore next */ (state) => state)(ClaimDetailContainerWithData);
